import '../styles/general.scss';
import '../scripts/_partials/catalog-filter/catalogFilter.module.js';
import '../scripts/auth/auth.module.js';
import '../styles/partials/header.scss';
import '../styles/partials/footer.scss';
import '../styles/partials/product-view.scss';
import '../styles/partials/login.scss';
import '../styles/views/home.scss';
import '../styles/views/product.scss';
import '../styles/views/errors.scss';
import '../styles/views/staticPage.scss';
import '../styles/views/request.scss';